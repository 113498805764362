$(document).ready(function(e) {
	$("#newFilms .oneFilm:first").addClass("active");
	$("#kinoOpis").click(function(){
		
		if($("#newFilms .active .smallOpis").hasClass("activeOpis"))
		{
			$("#newFilms .active .smallOpis").slideUp("slow").removeClass("activeOpis");
		}
		else
		{
			$("#newFilms .active .smallOpis").slideDown("slow").addClass("activeOpis");
		}
	});
	
	$(".onePost .imagesFull li a").fancybox();
	$(" .mainDescription a").fancybox();
	$(".imagesFull a").fancybox();
	$(".fullPost .imagesList a").attr({'rel':'oneImageList'}).fancybox().append("<span></span>");





});

